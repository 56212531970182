<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Density
                    </h5>
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                    "
                    @click="newUpload"
                  >
                    New
                  </v-btn>
                </div>
              </div>
              <v-card-text class="px-0 py-0">
                <v-row class="ml-2">
                  <v-col cols="12" md="4">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      >From</label
                    >
                    <v-menu
                      ref="mnu_frm_date"
                      v-model="mnu_frm_date"
                      :close-on-content-click="false"
                      :return-value.sync="mnu_frm_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="frm_date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-5
                          "
                          dense
                          flat
                          filled
                          solo
                          height="43"
                          placeholder="Date"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="green lighten-1"
                        header-color="primary"
                        v-model="frm_date"
                        no-title
                        scrollable
                        :max="to_date"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="mnu_frm_date = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.mnu_frm_date.save(frm_date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu></v-col
                  >
                  <v-col cols="12" md="4">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      >To</label
                    >
                    <v-menu
                      ref="mnu_to_date"
                      v-model="mnu_to_date"
                      :close-on-content-click="false"
                      :return-value.sync="mnu_to_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="to_date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-5
                          "
                          dense
                          flat
                          filled
                          solo
                          height="43"
                          placeholder="Date"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="green lighten-1"
                        header-color="primary"
                        v-model="to_date"
                        no-title
                        scrollable
                        :min="frm_date"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="mnu_to_date = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.mnu_to_date.save(to_date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="4" align-self="center" class="mt-5">
                    <v-btn
                      color="primary"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      class="
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                        mt-5
                      "
                      @click="search"
                    >
                      Search
                    </v-btn></v-col
                  >
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-card class="card-shadow border-radius-xl mt-6">
        <v-card-text class="card-padding">
          <v-data-table
            :items="tableData"
            :headers="headers"
            fixed-header
            class="table"
            :footer-props="{
              'items-per-page-options': [100, 200, 500, -1],
            }"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Density</v-toolbar-title>

                <v-spacer></v-spacer>
                <!-- <v-btn
                  color="primary"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                  "
                  @click="newUpload"
                >
                  New
                </v-btn> -->
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
export default {
  created() {
    this.initialize();
  },
  data() {
    return {
      //Dialog

      overlay: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,

      frm_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_frm_date: false,

      to_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_to_date: false,

      //
      tableData: [],
      headers: [
        {
          text: "Batch No",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 100,
        },
        {
          text: "GRN ",
          value: "grn_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 100,
        },
        {
          text: "Density",
          value: "density",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 100,
        },
      ],
    };
  },
  methods: {
    newUpload() {
      this.$router.push({ name: "NewDensity" });
    },
    async search() {
      this.tableData = await api.densitySearch(this.frm_date, this.to_date);
    },
    save() {
      let filename = this.file.name;

      let ext = filename.substring(
        filename.lastIndexOf(".") + 1,
        filename.length
      );
      if (ext == "xlsx") {
        try {
          this.overlay = true;
          let bodyFormData = new FormData();
          bodyFormData.append("file", this.file, this.file.name);
        } catch {}
      } else {
      }
    },

    async initialize() {
      this.tableData = await api.getData();
    },
  },
};
</script>

<style></style>
